import * as React from "react";
import { Link } from "gatsby";

import { Props } from "./types";

const LinkComponent = ({
  to,
  children,
  onClick,
  className,
  title,
  onMouseEnter,
  onMouseLeave,
  style,
}: Props): JSX.Element => {
  if (!to) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  const isDownload = to.substr(0, 9) === "data:text";
  const isExternal =
    to.substr(0, 4) === "http" || to.substr(0, 7) === "mailto:" || isDownload;

  // Check for missing "/" in relative urls
  if (!isExternal && !to.startsWith("/")) {
    to = `/${to}`;
  }

  if (!isExternal && to === "/home") {
    to = `/`;
  }

  return isExternal ? (
    <a
      className={className}
      href={to}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
      style={style}
      download={isDownload}
    >
      {children}
    </a>
  ) : (
    <Link
      style={style}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      title={title}
      to={to}
    >
      {children}
    </Link>
  );
};

export default LinkComponent;
